<template>
  <a-card title="Animatievideo Woonon">
    <div class="video-wrapper">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/wCTM6_qRSsQ?si=UZoEWpINx_aZBiF-" title="YouTube video player" frameborder="0" allow="encrypted-media;" allowfullscreen></iframe>
    </div>
  </a-card>
</template>

<script lang="ts" setup>
</script>

<style lang="less" scoped>
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
