type QuestionType = 'multiple' | 'open' | 'rating' | 'rating+' | 'selection'

export class QuestionData {
  ref!: number
  section = ''
  phrase = ''
  type!: QuestionType
  required = true
  answers: string[] = []
}

export class QuestionnaireData {
  title = ''
  intro = ''
  image = ''
  questions: QuestionData[] = []
}

export default class Questionnaire {
  id!: number
  slug!: string
  data!: QuestionnaireData
  completed = false
}

export class AnswerData {
  ref!: number
  answer: (string|number)[] = []
}

export class QuestionnaireResult {
  id: number|null = null
  answers: AnswerData[] = []
}
